export const PRICING_LIST = [
  {
    from: 0,
    to: 2,
    name: "Trucks",
    price: 165
  },
  {
    from: 3,
    to: 5,
    name: "Trucks",
    price: 325
  },
  {
    from: 6,
    to: 20,
    name: "Trucks",
    price: 545
  },
  {
    from: 21,
    to: 100,
    name: "Trucks",
    price: 1875
  },
  {
    from: 101,
    to: 1000,
    name: "Trucks",
    price: 7945
  },
  {
    from: 1001,
    to: Infinity,
    name: "Trucks",
    price: "Contact us directly.",
    isLast: true
  }
];
