import cx from "classnames";
import validate from "./validate";
import PropTypes from "prop-types";
import Modal from "../../ui/Modal";
import Input from "../../ui/Input";
import Loading from "../../ui/Loading";
import getTotalAndPrice from "./helpers";
import Radio from "../../ui/Input/Radio";
import useOnce from "../../hooks/useOnce";
import ModalContent from "./ModalContent";
import {useEffect, useState} from "react";
import PageTitle from "../../ui/PageTitle";
import {formatNumberToShow} from "../../utils";
import Checkbox from "../../ui/Input/Checkbox";
import { PRICING_LIST } from "../../constants";
import MESSAGES from "../../constants/messages";
import StepperFooter from "../shared/Stepper/components/StepperFooter";
import {editRegistrationDetails, getCarrierClassifications, getOperationsClassifications} from "../../Services";

import "./index.scss";

const initialCost = PRICING_LIST[0].price;

const RegistrationDetails = ({id, onNext, onPrev, state, saveState, data:propsData, carrierInfo}) => {
  const years = carrierInfo.RegistrationYear;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [errors, setErrors] = useState({hasError: false, errors: {}});
  const [data, setData] = useState({
    costForOneYear: initialCost,
    additionalVehiclesCount: 0,
    carrierClassificationIds: [],
    totalCost: initialCost * years.length,
    operationClassificationIds: [],
    totalVehiclesExemptionsCount: 0,
    passengerVehiclesExemptionsCount: 0,
    additionalVehiclesExemptionsCount: 0,
    isUpdatingOperationsClassification: false,
    straightTrucksAndTractorsExemptionsCount: 0,
    passengerVehiclesCount: propsData.PassengerVehicles,
    straightTrucksAndTractorsCount: propsData.StraightTrucksAndTractors,
    totalVehiclesCount: propsData.PassengerVehicles + propsData.StraightTrucksAndTractors,
    ...state
  });
  const [option, setOption] = useState("optionA");
  const [carrierClassification, setCarrierClassification] = useState([]);
  const [operationClassification, setOperationClassification] = useState([]);

  const getData = (getCb, setCb) => {
    setLoading(true);
    getCb()
    .then(response => {
      if (response.HasError) {
        response.errors.map(({ errorMessage }) => alert.show(errorMessage, {type: "error"}));
      } else {
        setCb(response.Data)
      }
    })
    .catch(err=> {
      alert.show(err.message || err, {type: "error"});
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const setInitialValue = () => {
    const {total, totalCost, costForOneYear} = getTotalAndPrice(data, years.length);
    setData(data => ({
      ...data,
      totalCost,
      costForOneYear,
      totalVehiclesCount: total
    }));
  };

  useOnce(() => {
    getData(getCarrierClassifications, setCarrierClassification);
    getData(getOperationsClassifications, setOperationClassification);
    setInitialValue();
  });

  useEffect(() => {
    if(errors.hasError) {
      setErrors(validate(data));
    }
  }, [data, errors.hasError]);

  const onClassificationChange = e => {
    const isUpdatingOperationsClassification = e.target.value === "carrier";
    setData(prev => ({
      ...prev,
      isUpdatingOperationsClassification,
    }));
  };

  const onSelectClassification = (id, key) => {
    let selected = [...data[key]];
    if (selected.includes(id)) {
      selected = selected.filter(item => item !== id);
    } else {
      selected.push(id);
    }
    setData(prev => ({
      ...prev,
      [key]: selected
    }));
  };

  const hasItemOnArray = (arr, items) => {
    return items.some(item => arr.includes(item));
  };

  const disabled = (id) => {
    const {carrierClassificationIds} = data;
    switch (id){
    case 1:
      return hasItemOnArray(carrierClassificationIds, [4, 5]);
    case 2:
      return hasItemOnArray(carrierClassificationIds, [4, 5]);
    case 3:
      return hasItemOnArray(carrierClassificationIds, [4, 5]);
    case 4:
      return hasItemOnArray(carrierClassificationIds, [1, 2, 3, 5]);
    case 5:
      return hasItemOnArray(carrierClassificationIds, [1, 2, 3, 4]);
    default:
      return false;
    }
  };

  const handleVehicleChange = (e, key) => {
    const value = parseInt(e.currentTarget.value);
    const newData = {...data, [key]: value > 0 ? value : 0};
    const {total, totalCost, costForOneYear} = getTotalAndPrice(newData, years.length);

    if (total !== null && total < 0) {
      return;
    }

    setData({
      ...newData,
      totalCost,
      costForOneYear,
      totalVehiclesCount: total,
    });
  };

  const handleOptionChange = (e) => {
    const {value} = e.target;
    const isOptionB = value === "optionB";
    isOptionB && setOpen(true);
    setOption(value);
    const newData = {
      ...data,
      passengerVehiclesCount: isOptionB ? 0 :  propsData.PassengerVehicles,
      straightTrucksAndTractorsCount: isOptionB ? 0 : propsData.StraightTrucksAndTractors,
    };
    const {total, totalCost, costForOneYear} = getTotalAndPrice(newData, years.length);

    setData({
      ...newData,
      totalCost,
      costForOneYear,
      totalVehiclesCount: total,
    });
  };

  const onSave = () => {
    const res = validate(data);
    if (res.hasError) {
      setErrors(res);
      return;
    }

    const d = {
      totalCost: data.totalCost,
      totalVehiclesCount: data.totalVehiclesCount,
      passengerVehiclesCount: data.passengerVehiclesCount,
      additionalVehiclesCount: data.additionalVehiclesCount,
      carrierClassificationIds: data.carrierClassificationIds,
      operationClassificationIds: data.operationClassificationIds,
      straightTrucksAndTractorsCount: data.straightTrucksAndTractorsCount,
      passengerVehiclesExemptionsCount: data.passengerVehiclesExemptionsCount,
      additionalVehiclesExemptionsCount: data.additionalVehiclesExemptionsCount,
      isUpdatingOperationsClassification: data.isUpdatingOperationsClassification,
      straightTrucksAndTractorsExemptionsCount: data.straightTrucksAndTractorsExemptionsCount,
    };
    setLoading(true);
    editRegistrationDetails(id, d)
      .then(response => {
        if (response.HasError) {
          response.errors.map(({ errorMessage }) => alert.show(errorMessage, {type: "error"}));
        } else {
          saveState({
            ...d,
            totalCost: data.totalCost,
            costForOneYear: data.costForOneYear
          });
          onNext();
        }
      })
      .catch(err => {
        alert.show(err.message || err, {type: "error"});
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="registration-details">
      <p className="group-input-label">{MESSAGES.ARE_YOU_UPDATING_ANY_OF_THE_COMPANY_OPERATIONS_CLASSIFICATION}</p>
      <div className="radio-block">
        <Radio
          label="Yes"
          value="carrier"
          name="classification"
          checked={data.isUpdatingOperationsClassification}
          onChange={onClassificationChange}
        />
        <Radio
          label="No"
          value="operation"
          name="classification"
          onChange={onClassificationChange}
          checked={!data.isUpdatingOperationsClassification}
        />
      </div>
      {data.isUpdatingOperationsClassification &&
        <>
          <p className={cx("group-input-label", {error: errors.errors.operationClassificationIds})}>
            <span className="required">*</span>{MESSAGES.OPERATIONS_CLASSIFICATION}
          </p>
          {errors.errors.operationClassificationIds && <p className="required-field-message">Error: Required field not filled</p>}
          <div className="checkbox-block">
            {operationClassification.map(({Id, Title}) => (
              <div className="checkbox-wrapper" key={Id}>
                <Checkbox
                  value={Id}
                  label={Title}
                  name="classificationList"
                  checked={data.operationClassificationIds.includes(Id)}
                  onChange={() => onSelectClassification(Id, "operationClassificationIds")} />
              </div>
            ))}
          </div>
        </>
      }
      <p className={cx("group-input-label", {error: errors.errors.carrierClassificationIds})}>
        <span className="required">*</span>{MESSAGES.CARRIER_CLASSIFICATION}
      </p>
      {errors.errors.carrierClassificationIds && <p className="required-field-message">Error: Required field not filled</p>}
      <div className="checkbox-block">
        {carrierClassification.map(({Id, Title}) => (
          <div className="checkbox-wrapper" key={Id}>
            <Checkbox
              value={Id}
              label={Title}
              name="classificationList"
              disabled={disabled(Id)}
              checked={data.carrierClassificationIds.includes(Id)}
              onChange={() => onSelectClassification(Id, "carrierClassificationIds")} />
          </div>
        ))}
      </div>
      <p className="group-input-label">{MESSAGES.VEHICLES}</p>
      <div className="horizontal-radio-block">
        <Radio
          name="option"
          value="optionA"
          label={MESSAGES.OPTION_A}
          checked={option === "optionA"}
          onChange={handleOptionChange}
        />
        <Radio
          name="option"
          value="optionB"
          label={MESSAGES.OPTION_B}
          checked={option === "optionB"}
          onChange={handleOptionChange}
        />
      </div>
      <div className="table-block">
        <table className="table">
          <thead>
          <tr>
            <th />
            <th>{MESSAGES.VEHICLES}</th>
            <th>{MESSAGES.EXEMPTIONS}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{MESSAGES.STRAIGHT_TRUCKS_AND_TRACTORS}</td>
            <td>
              <Input
                disabled={option === "optionA"}
                value={data.straightTrucksAndTractorsCount}
                onChange={e => handleVehicleChange(e, "straightTrucksAndTractorsCount")}
              />
            </td>
            <td>
              <Input
                value={data.straightTrucksAndTractorsExemptionsCount}
                onChange={e => handleVehicleChange(e, "straightTrucksAndTractorsExemptionsCount")}
              />
            </td>
          </tr>
          <tr>
            <td>{MESSAGES.PASSENGER_VEHICLES}</td>
            <td>
              <Input
                disabled={option === "optionA"}
                value={data.passengerVehiclesCount}
                onChange={e => handleVehicleChange(e, "passengerVehiclesCount")}
              />
            </td>
            <td>
              <Input
                value={data.passengerVehiclesExemptionsCount}
                onChange={e => handleVehicleChange(e, "passengerVehiclesExemptionsCount")}
              />
            </td>
          </tr>
          <tr>
            <td>{MESSAGES.ADDITIONAL_VEHICLES}</td>
            <td>
              <Input
                value={data.additionalVehiclesCount}
                onChange={e => handleVehicleChange(e, "additionalVehiclesCount")}
              />
            </td>
            <td>
              <Input
                value={data.additionalVehiclesExemptionsCount}
                onChange={e => handleVehicleChange(e, "additionalVehiclesExemptionsCount")}
              />
            </td>
          </tr>
          </tbody>
        </table>
        <div className="order-details">
          <PageTitle title="Order Details" />
          <div className="total-row">
            <label>{MESSAGES.VEHICLE_TOTAL}</label>
            <span>{data.totalVehiclesCount}</span>
          </div>
          <div className="total-row total-cost">
            {data.costForOneYear === null
             ? <a className="contact-us-directly" href={MESSAGES.CONTACT_INFO.PHONE}>{MESSAGES.CONTACT_US_DIRECTLY}</a>
             : (
               <>
                 <label>{MESSAGES.TOTAL_COST_FOR_YEAR}</label>
                 <span>${formatNumberToShow(data.costForOneYear)}.00</span>
               </>
             )
            }
          </div>
          <div className="total-row">
            <label>{MESSAGES.YEARS}</label>
            <span>
              {years.join(", ")}
            </span>
          </div>
          <div className="total-row total-cost">
            {data.totalCost !== null && (<>
              <label>{MESSAGES.TOTAL_COST}</label>
              <span>${formatNumberToShow(data.totalCost)}.00</span>
            </>)}
          </div>
        </div>
      </div>
      <StepperFooter
        onClickNext={onSave}
        onClickPrevious={onPrev}
        hideNext={data.totalCost === null}
      />
      <Modal
        open={open}
        title={MESSAGES.OPTION_B_WARNINGS}
      >
        <ModalContent onChange={handleOptionChange} onClose={setOpen} />
      </Modal>
      <Loading isLoading={loading} />
    </div>
  );
};

RegistrationDetails.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default RegistrationDetails;
